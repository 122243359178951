import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FilteredList,
  MultipleComponentIterator,
  InternalLink,
  Image,
  MultipleComponentIteratorMap,
  FadeReveal,
  FullImageCard,
  FishermanIcon,
  Price,
  OutboundLink,
  SocialMediaV2,
  Content,
} from "@bluefin/components";
import { Grid, Header, Button, List, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessFile,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <div className={"content-section"}>
            <Grid
              stackable={true}
              doubling={false}
              columns={1}
              textAlign={"center"}
            >
              <Grid.Column width={8} className={"markdown-column"}>
                <Header
                  as={"h1"}
                  textAlign={"center"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_header",
                    defaultValue: "Services",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <FilteredList
            items={allFishermanBusinessMenuCategory.nodes}
            itemFilterKey={"schedule.name"}
            childItemsKey={"iterator"}
            defaultFilterValue={"All Services"}
            displayAllFilterValue={"All Services"}
            filterValues={allFishermanBusinessMenuSchedule.nodes}
            filterValueKey={"name"}
            filterValuesComponent={<Button basic={true} />}
            className={"services-filtered-list"}
            parentContainer={<div className={"services-section"} />}
          >
            <MultipleComponentIterator
              components={[
                {
                  component: <div className={"service-container"} />,
                  propMap: { key: "_id" },
                  children: [
                    {
                      component: (
                        <Grid
                          className={"category-info"}
                          stackable={true}
                          doubling={true}
                          columns={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <Grid.Column className={"info-column"} width={8} />
                          ),
                          children: [
                            {
                              component: (
                                <Header className={"name"} as={"h2"} />
                              ),
                              propMap: { content: "name" },
                            },
                            {
                              component: (
                                <MarkdownContent className={"description"} />
                              ),
                              propMap: { content: "description" },
                            },
                            {
                              component: (
                                <Button
                                  basic={true}
                                  content={"View Services"}
                                  event={{
                                    category: "Services",
                                    action: "View Category Details",
                                  }}
                                  as={InternalLink}
                                />
                              ),
                              propMap: {
                                "event.label": "name",
                                to: createServiceCategorySlug,
                              },
                            },
                          ],
                        },
                        {
                          component: (
                            <Grid.Column className={"image-column"} width={8} />
                          ),
                          children: [
                            {
                              component: <Image className={"image"} />,
                              propMap: {
                                src: withNamedArgs({
                                  func: getBusinessFiles,
                                  args: {
                                    businessFiles:
                                      allFishermanBusinessFile.nodes,
                                    numToSelect: 1,
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      component: (
                        <Card.Group
                          className={"category-items"}
                          stackable={true}
                          itemsPerRow={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <MultipleComponentIteratorMap
                              lookupField={"_id"}
                              limit={4}
                              iterator={allFishermanBusinessMenuItem.nodes}
                              components={[
                                {
                                  component: (
                                    <FadeReveal
                                      className={"ui card"}
                                      triggerOnce={true}
                                    />
                                  ),
                                  propMap: { key: "_id" },
                                  children: [
                                    {
                                      component: (
                                        <FullImageCard
                                          as={InternalLink}
                                          link={true}
                                          event={{
                                            category: "Services",
                                            action: "View Item Details",
                                          }}
                                        />
                                      ),
                                      propMap: {
                                        image: withNamedArgs({
                                          func: getBusinessFiles,
                                          args: {
                                            businessFiles:
                                              allFishermanBusinessFile.nodes,
                                            numToSelect: 1,
                                          },
                                        }),
                                        "event.label": "name",
                                        to: withArgs({
                                          func: createServiceItemSlug,
                                          args: [
                                            allFishermanBusinessMenuCategory.nodes,
                                          ],
                                        }),
                                      },
                                      children: [
                                        {
                                          component: <Card.Header as={"h3"} />,
                                          propMap: { content: "name" },
                                        },
                                        {
                                          component: (
                                            <FishermanIcon
                                              iconName={"arrow-right"}
                                            />
                                          ),
                                        },
                                        {
                                          component: (
                                            <Content.Markup
                                              isMarkdown={false}
                                              className={
                                                "custom-category-description"
                                              }
                                            />
                                          ),
                                          propMap: { content: "description" },
                                        },
                                        {
                                          component: <Price as={"h3"} />,
                                          propMap: {
                                            amount: "variations[0].price",
                                          },
                                        },
                                        {
                                          component: (
                                            <Button
                                              content={"Learn More"}
                                              primary={true}
                                              size={"small"}
                                              as={InternalLink}
                                            />
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ]}
                            />
                          ),
                          propMap: { targetValues: "items" },
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
          </FilteredList>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            className={"specials-section"}
            textAlign={"center"}
            centered={true}
          >
            <Grid.Row>
              <Header
                as={"h2"}
                content={"Discover Our Exclusive Offers"}
                textAlign={"center"}
              />
              <p>
                Discover our best deals designed to enhance your beauty routine.
                Limited-time offers—book now to lock in these amazing savings!
              </p>
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={5} textAlign={"center"}>
                <h3>Lip Filler - $599</h3>
                <List
                  items={[
                    "Achieve fuller, more youthful lips with 1ml of premium filler.",
                  ]}
                />
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column width={5} textAlign={"center"}>
                <h3>Full Body Laser Hair Removal - $299</h3>
                <List
                  items={[
                    "Enjoy smooth, hair-free skin from head to toe with this unbeatable deal.",
                  ]}
                />
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column width={5} textAlign={"center"}>
                <h3>The Perfect Derma Peel - $299</h3>
                <List
                  items={[
                    "Reveal radiant, refreshed skin with our most popular chemical peel.",
                  ]}
                />
                <Button
                  to={"https://trumedspa.myaestheticrecord.com/online-booking"}
                  as={OutboundLink}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={"Book Now"}
                  aria-label={"Book an Appointment"}
                  primary={true}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Button
                to={"/specials/"}
                as={InternalLink}
                content={"Explore Our Specials"}
                primary={true}
              />
            </Grid.Row>
          </Grid>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              textAlign={"center"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <div
              className={"elfsight-app-49969dd7-134d-43d0-8882-9aee356f1f93"}
              data-elfsight-app-lazy={true}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
        files
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
  }
`;
